:root {
  --theme-color: #36b0ff !important;
  --option-border-color: #ffffff99 !important;
  --option-text-color: white !important;
  --content-color: white !important;
  --active-option-back-color: white !important;
  --active-option-color: #00b3ff !important;
  --focus-color: #36b0ff !important;
  --scrollbar-color: #9cddfe !important;
  --navlink-active-tab-background: #36b0ff !important;
  --navlink-active-tab-color: #004361 !important;
  --info-page-background: #ff4f34 !important;
  --takeseat-background: #ff7d6d !important;
  --infoBox-background: #2fa1ed !important;
}
.dark-color {
  --theme-color: white !important;
  --option-border-color: #004361 !important;
  --option-text-color: #004361 !important;
  --content-color: #004361 !important;
  --active-option-back-color: #004361 !important;
  --active-option-color: white !important;
  --focus-color: #004361 !important;
  --scrollbar-color: #00437e !important;
  --navlink-active-tab-background: #004361 !important;
  --navlink-active-tab-color: white !important;
  --info-page-background: white !important;
  --takeseat-background: white !important;
  --infoBox-background: white !important;
}

.backdrop {
  background: url("../Images/optoBackground.png");
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content: space-around;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}

.form-control:focus {
  background-color: var(--focus-color);
}
.dark-color .dateWrapper .form-select:focus {
  border-color: var(--focus-color) !important;
}

.popup-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  align-self: center;
}

.child-popup {
  width: 250px;
  background: var(--infoBox-background) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 10px;
}

.font-14 {
  font-size: 14px !important;
}

.custom-field {
  border-color: var(--option-border-color) !important;
}

.no-shadow {
  box-shadow: none !important;
}

.custom-field:focus {
  box-shadow: none;
}

.popup-heading {
  color: #004462;
  font-weight: bold;
}

.popup-content {
  color: var(--content-color);
  font-size: 14px;
  margin: 10px 0px;
}

.button-popup {
  width: 60px;
  font-size: 11px;
  border-width: 0px;
  background-color: #004462;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.slider-dot-1 {
  height: 11px;
  width: 11px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-dot-2 {
  height: 7px;
  width: 7px;
  background: #004561;
  border-radius: 50%;
}
.MuiSlider-rail {
  color: var(--content-color) !important;
}

.custom-container {
  display: flex;
}

.mobile-box {
  display: flex;
  background-color: #36b0ff;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.pc-box {
  display: flex;
  background-color: var(--theme-color);
  width: 310px;
  box-shadow: 10px 9px 28px 1px #34343452;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50px;
  padding: 15px 0px;
  /* height: 95vh; */
  height: 100%;
}
.post-box-red {
  background-color: var(--info-page-background) !important;
}
.takeSeat .logoCenter {
  height: 90px;
  width: 90px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.takeSeat {
  background-color: var(--takeseat-background) !important;
  text-align: center;
  padding: 10px;
  padding-top: 90px;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  padding-bottom: 100px;
  border-radius: 8px;
}
.dark-color .takeSeat {
  border: 1px solid #004461;
}
.takeApproval {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  padding: 28px;
  padding-top: 90px;
}
.takeSeat p {
  margin: 0;
  color: #004461;
  font-weight: bold;
  font-size: 18px;
}
.approval {
  margin: 0;
  color: #004461;
  font-weight: bold;
  font-size: 14px;
}
.detailApproval {
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: #ffffff !important;
  margin-top: 40px !important;
}

@media screen and (min-height: 720px) and (max-height: 899px) {
  .pc-box {
    padding: 50px 0px !important;
  }
}

@media screen and (min-height: 900px) and (max-height: 1079px) {
  .pc-box {
    padding: 80px 0px !important;
  }
}

@media screen and (min-height: 1080px) {
  .pc-box {
    padding: 100px 0px !important;
  }
  .logo-small {
    margin-bottom: 30px;
    width: 60px !important;
    height: 60px !important;
  }
  .info2 {
    height: 205px !important;
  }
  .info {
    padding: 20px 0px !important;
  }
  .pc-box-pd .smilie {
    margin-top: 100px !important;
  }
  .pc-box-pe .smilie {
    margin-top: 110px !important;
  }
  .button-next {
    margin-top: 30px !important;
  }
}

@media screen and (min-height: 900px) and (max-height: 1079px) {
  .info2 {
    height: 205px !important;
  }
}

@media screen and (max-width: 1920px) {
  .pc-box {
    width: 390px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
  .pc-box-pd {
    width: 390px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
  .pc-box-pe {
    width: 390px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
}
@media screen and (max-width: 1680px) {
  .pc-box {
    width: 300px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
  .pc-box-pd {
    width: 300px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
  .pc-box-pe {
    width: 300px !important;
    /* height: 70vh !important; */
    height: 100%;
  }
}
@media screen and (max-width: 1367px) {
  .pc-box {
    width: 290px !important;
    /* height: 90vh !important; */
    height: 100%;
  }
  .pc-box-pd {
    width: 290px !important;
    /* height: 90vh !important; */
    height: 100%;
  }
  .pc-box-pe {
    width: 290px !important;
    /* height: 90vh !important; */
    height: 100%;
  }
  .answer {
    height: -webkit-fill-available;
    overflow-y: scroll;
    /* padding-right: 5px; */
  }
  .pc-box-pd .smilie {
    margin-top: 50px !important;
  }
  .pc-box-pe .smilie {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-box {
    width: 290px !important;
    /* height: 95vh !important; */
    height: 100%;
  }
  .pc-box-pd {
    width: 290px !important;
    /* height: 95vh !important; */
    height: 100%;
  }
  .pc-box-pe {
    width: 290px !important;
    /* height: 95vh !important; */
    height: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiInputBase-input {
  color: var(--content-color) !important;
}

@media screen and (max-width: 820px) {
  .answer {
    height: -webkit-fill-available;
    overflow-y: scroll;
    /* padding-right: 5px; */
  }
  .content-div {
    width: 100%;
    margin: 0px 12px;
    padding-top: 70px !important;
    overflow: hidden;
    padding-bottom: 185px;
  }
  .content-div-2 {
    overflow-y: scroll;
    height: -webkit-fill-available;
  }

  .h-custom {
    height: -webkit-fill-available;
    overflow-y: scroll;
    /* padding-right: 5px; */
  }
}

.h-custom {
  height: -webkit-fill-available;
  overflow-y: scroll;
  /* padding-right: 5px; */
}

.pre-triage {
  height: -webkit-fill-available;
  overflow-y: scroll;
  /* padding-right: 5px; */
  /* padding-bottom: 125px; */
}

.custom-padding {
  height: -webkit-fill-available;
  overflow-y: hidden;
  /* padding-right: 5px; */
  /* padding-bottom: 125px; */
}

.answer {
  height: -webkit-fill-available;
  overflow-y: scroll;
  /* padding-right: 5px; */
}

.mobile-box-pd {
  display: flex;
  background-color: #ff4f34;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.mobile-box-pe {
  display: flex;
  background-color: var(--theme-color);
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.react-tel-input .country-list {
  width: 230px !important;
  padding-left: 10px !important;
}
.overflow-initial {
  overflow: initial !important;
}
.pc-box-pd .smilie {
  width: 130px;
  margin-top: 50px;
}
.pc-box-pe .smilie {
  width: 130px;
  margin-top: 80px;
}
.mobile-box-pd .smilie {
  width: 130px;
}
.mobile-box-pe .smilie {
  width: 130px;
}
.pc-box-pd {
  display: flex;
  background-color: var(--info-page-background);
  width: 310px;
  box-shadow: 10px 9px 28px 1px #34343452;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50px;
  padding: 15px 0px;
  /* height: 95vh; */
  height: 100%;
}

.pc-box-pe {
  display: flex;
  background-color: var(--theme-color);
  width: 310px;
  box-shadow: 10px 9px 28px 1px #34343452;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50px;
  padding: 15px 0px;
  /* height: 95vh; */
  height: 100%;
}

.logo-small {
  height: 45px;
  width: 45px;
  border-radius: 100%;
}

.logo-smaller {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.ecds-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 20px;
}
.takeSeat-Container {
  align-items: center;
  padding: 30px 27px;
}

.question {
  color: #004462;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0px;
}

.content-div {
  width: 100%;
  margin: 0px 12px;
  padding-top: 40px;
  overflow: hidden;
  padding-bottom: 185px;
}

.content-div-2 {
  overflow-y: scroll;
  height: -webkit-fill-available;
}
.content-div-3 {
  width: 100%;
  margin: 0px 12px;
  padding-top: 40px;
  overflow: hidden;
  padding-bottom: 220px;
}
.content-div-4 {
  width: 100%;
  margin: 0px 12px;
  padding-top: 40px;
  overflow: hidden;
  padding-bottom: 75px;
}
.content-div-5 {
  width: 100%;
  margin: 0px 12px;
  padding-top: 40px;
  overflow: hidden;
  padding-bottom: 150px;
}

.h-min {
  height: min-content !important;
  padding-bottom: 0;
}

.arrow-left {
  position: absolute;
  left: 5px;
  top: 45%;
}

.arrow-left-img {
  width: 10px;
  cursor: pointer;
}

.form-control::placeholder {
  color: var(--content-color);
  opacity: 1;
}
.h-80p {
  height: 80px;
}
.h-100p {
  height: 80px;
}
.form-control:-ms-input-placeholder {
  color: white;
}

.form-control::-ms-input-placeholder {
  color: white;
}

.form-select {
  color: white !important;
  background: none;
}
.form-select option {
  color: #004461 !important;
}
.form-select:focus {
  border-color: #2cc6ff;
  box-shadow: none;
}
.pd-10 {
  padding: 10px;
}

.mobile-box-pd .smilie {
  width: 130px;
}
.mobile-box-pe .smilie {
  width: 130px;
}
.w-100 {
  width: 100%;
}
.text-fields {
  background-color: transparent !important;
  border: 1px solid var(--option-border-color) !important;
  box-shadow: none !important;
  font-size: 13px;
  width: 100% !important;
  padding: 10px;
}
.text-field2 {
  background-color: transparent !important;
  border: 1px solid var(--option-border-color) !important;
  border-left: none !important;
  box-shadow: none !important;
  font-size: 13px;
  width: 100% !important;
  padding: 10px;
  padding-left: 0;
}

.selectField {
  width: 100%;
}
.loader_div {
  width: 52px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 7px;
  padding: 15px;
}

@media screen and (min-width: 900px) {
  .text-fields {
    width: 100% !important;
  }
  .text-field2 {
    width: 100% !important;
  }

  .info {
    padding: 0px 20px !important;
  }
}

/* @media screen and (min-width: 1920px) {
  .pc-box {
    width: 420px !important;
    height: 65vh !important;
  }
} */
@media screen and (min-width: 2560px) {
  .pc-box {
    width: 420px !important;
    /* height: 50vh !important; */
    height: 100%;
  }
  .pc-box-pd {
    width: 420px !important;
    /* height: 50vh !important; */
    height: 100%;
  }
  .pc-box-pe {
    width: 420px !important;
    /* height: 50vh !important; */
    height: 100%;
  }
}

.no-resize {
  resize: none !important;
}

.trans-background {
  background-color: transparent !important;
}
.form-select {
  color: var(--content-color) !important;
}
.colored-border {
  border: 1px solid var(--option-border-color);
}

.heading {
  font-size: 50px;
  font-weight: bold;
  color: #004462;
  text-align: center;
}
.pc-box-pd .heading {
  font-size: 35px;
  font-weight: bold;
  color: #004462;
  text-align: center;
  line-height: 40px;
  margin-bottom: 15px;
}
.mobile-box .heading {
  margin-top: 20px !important;
  font-size: 60px;
}
.mobile-box .logo-small {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}
.info {
  font-size: 13px;
  color: var(--content-color);
  text-align: center;
  width: 265px !important;
}
.info2 {
  font-size: 13px;
  color: var(--content-color);
  text-align: center;
  width: 265px !important;
  height: 200px;
}
.info3 {
  font-size: 13px;
  color: white !important;
  text-align: center;
  width: 265px !important;
}

.button-next {
  background-color: #004461;
  border-width: 0px;
  height: 35px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-box .button-next {
  margin-top: 45px !important;
}
.br {
  border-radius: 5px !important;
}

.button-next span {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px 20px;
}

.bold {
  font-weight: bold;
}

.button-started {
  background-color: #004461;
  border-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-started span {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px 40px;
}

.font-13 {
  font-size: 13px !important;
}

.button-ecds-next {
  background-color: #004461;
  border-width: 0px;
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-ecds-next span {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px 30px;
}
.button-post-triage-next {
  background-color: #004461;
  border-width: 0px;
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-post-triage-next span {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px 80px;
}

.logo-smallest {
  width: 20px;
}

.answer-div {
  width: 100%;
  border: 1px solid var(--option-border-color);
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.slider {
  position: absolute;
  top: 20px;
}

.color-white {
  color: var(--content-color) !important;
}

.c-blue {
  color: var(--active-option-color) !important;
  background-color: var(--active-option-back-color) !important;
}

.answer-multiple {
  border: 1px solid var(--option-border-color) !important;
  border-radius: 5px;
  padding: 3px 10px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.white-background {
  background-color: white;
}

.active-option {
  background-color: var(--active-option-back-color);
  color: var(--active-option-color) !important;
}

.inactive-option {
  background-color: 004461;
  color: var(--option-text-color);
  border: 1px solid var(--option-border-color);
}

.phone-input {
  width: 70px;
  border: 1px solid var(--option-border-color);
  border-right: none;
  background: transparent;
  color: var(--content-color);
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.country-selection {
  min-width: max-content;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 0;
}
.answer:hover::-webkit-scrollbar,
.h-custom:hover::-webkit-scrollbar,
.card-scroll:hover::-webkit-scrollbar,
.scroll-visible:hover::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.mainComp {
  padding: 60px 20px;
  display: block !important;
  text-align: center;
}
.hospitalInputs {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.hospitalInputs input {
  width: 14.5% !important;
  height: 40px;
  padding: 0;
  font-size: 18px;
  text-align: center;
}
.hospitalId {
  /* box-sizing: border-box; */
  width: 36px !important;
  height: 44px;
  left: 285.26px;
  top: 234.35px;
  color: var(--content-color);
  background: var(--theme-color);
  border: 1px solid var(--content-color);
  border-radius: 6px;
  margin: 0 3px;
  outline: 0;
}
/* .focusStyle {
  border-color: #00b3ff;
} */
.button-ecds-nexts {
  background-color: #004461;
  border-width: 0px;
  position: absolute;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 50%;
  transform: translate(50px, 10px);
}
.button-ecds-nexts span {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin: 0px 30px;
}
.get-started-btn {
  background-color: #004461;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: none;
}
.welcome-info {
  color: var(--content-color);
  font-size: 1.5rem;
  font-weight: 500;
}
.body-tabs .nav-tabs {
  font-size: 11px;
  border-radius: 10px;
  border: 1px solid var(--content-color);
  background: white;
  font-weight: 700;
  justify-content: space-between;
}
.body-tabs .nav-link.active {
  background: var(--navlink-active-tab-background);
  border-radius: 10px;
  color: var(--navlink-active-tab-color);
}
.body-tabs .nav-item {
  outline: none;
  border: 0;
  width: 50%;
}
.body-tabs .nav-link {
  color: #004461;
  border: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
}
.body-tabs .nav-link.active:hover {
  border: 0;
}
.body-tabs .nav-link:hover {
  border: 0;
}
.content-color {
  color: var(--content-color) !important;
}
.body-parts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.body-parts .card {
  position: relative;
  background-color: transparent;
  border: 1px solid var(--content-color);
  width: 48%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 5px;
  padding-top: 40px;
}
.body-parts .card h3 {
  color: #004461;
  font-size: 14px;
}
.body-parts .card p {
  color: var(--content-color);
  font-size: 12px;
  margin: 0 0 7px;
  line-height: 16px;
}
.body-parts .parts-img {
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-20px, -12px);
}
.body-parts .parts-img img {
  width: 40px;
  height: 40px;
  border: 3px solid #004461;
  border-radius: 100px;
  object-fit: cover;
}
.body-parts .search {
  position: relative;
}
.body-parts .search img {
  position: absolute;
  width: 16px;
  top: 7px;
  left: 8px;
}
.body-parts .search input {
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid #fff;
  width: 100%;
  margin-bottom: 20px;
  outline: 0;
  padding: 1px 10px;
  padding-left: 30px;
}
/* .body-tabs .answer-multiple {
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid #fff;
  width: 100%;
  margin-bottom: 20px;
  outline: 0;
  padding: 1px 10px;
  padding-left: 30px;
}
.body-tabs .answer-multiple img{
  width: 16px;
}
.body-tabs .answer-multiple input{
  padding: 0;
} */
.body-tabs {
  padding-right: 0;
  overflow: unset;
}
.scroll-visible {
  overflow: auto;
  height: 100%;
  padding-right: 0;
  transition: all 0.4s ease-in-out;
  flex-wrap: wrap;
}
.card-scroll .card {
  margin-top: 12px;
  margin-bottom: 8px;
}
.MuiPickersArrowSwitcher-spacer {
  width: 4px !important;
}
.MuiClockPicker-root {
  width: 280px !important;
}
.MuiPickersArrowSwitcher-root {
  right: -4px !important;
}
.css-epd502 {
  width: 280px !important;
}

.MuiButtonBase-root {
  padding: 5px !important;
}
.MuiButtonBase-root .MuiTypography-root {
  font-size: 10px !important;
}
.output-wrap {
  direction: ltr;
}
[dir="rtl"] .phone-input {
  border: 1px solid var(--option-border-color);
  border-left: none;
  border-radius: 0 7px 7px 0;
}
[dir="rtl"] .slider {
  direction: ltr;
}
[dir="rtl"] .body-tabs .nav-tabs {
  padding: 0;
}
[dir="rtl"] .MuiInputBase-root > .MuiInputAdornment-root {
  margin: 0 0 0 8px;
}
[dir="rtl"] .text-field2 {
  border: 1px solid var(--option-border-color) !important;
  border-right: none !important;
  border-radius: 7px 0 0 7px !important;
  padding: 10px 0 10px 10px;
}
[dir="rtl"] .country-selection {
  margin-left: 0;
  margin-right: 10px;
}
[dir="rtl"] .arrow-left {
  left: unset;
  right: 10px;
  transform: scale(-1);
}
[dir="rtl"] .dateWrapper .form-select {
  background-position: left 5px center;
}
.switch-veriant {
  position: absolute;
  right: 32px;
  top: 38px;
  display: flex;
  align-items: center;
}
.switch-veriant p {
  margin: 0 10px 0 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--content-color);
}
.switch-veriant .MuiSwitch-sizeMedium {
  width: 31px;
  height: 20px;
  padding: 0;
}
.switch-veriant .MuiSwitch-thumb {
  width: 10px;
  height: 10px;
  box-shadow: none;
}
.switch-veriant .MuiButtonBase-root {
  padding: 0 !important;
}
.switch-veriant .MuiSwitch-track {
  border-radius: 30px;
  opacity: 1 !important;
}
.switch-veriant .MuiSwitch-switchBase {
  top: 5px;
  left: 4px;
  color: #36adff !important;
}
.switch-veriant .MuiSwitch-switchBase.Mui-checked {
  left: -4px;
  color: #004361 !important;
}
.switch-veriant .MuiSwitch-track {
  background-color: #004361;
  border: 4px solid #004361;
}
.switch-veriant .Mui-checked + .MuiSwitch-track {
  background-color: #fff !important;
}
.dark-color .logo-smaller {
  border: 1px solid;
}
.dark-color .arrow-left-img {
  filter: brightness(0.6);
}
.check_box {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  color: var(--option-text-color);
  font-weight: 400;
  position: relative;
  margin-left: 5px;
}
.check_box input {
  position: absolute;
  opacity: 0;
}
.check_box input:checked ~ span::before {
  background-color: transparent;
}
.check_box span {
  display: flex;
  align-items: center;
  gap: 3px;
}
.check_box span::before {
  content: "";
  display: flex;
  width: 10px;
  height: 10px;
  border: 3px solid var(--option-text-color);
  background-color: var(--option-text-color);
  border-radius: 100%;
}
.check_question {
  align-items: center;
  margin-bottom: 10px;
}
.check_question > span {
  color: var(--option-text-color);
  width: 72%;
  font-size: 11px;
  padding-right: 7px;
}
.check_question_wrap {
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
}
.check_question_wrap h2 {
  margin: 15px 0;
  font-size: 20px;
  font-weight: bold;
}
.info-icon-color {
  color: var(--option-text-color);
}
.customdropdown {
  background-color: #fff;
  padding: 10px 2px 2px;
  border-radius: 10px 10px 5px 5px;
  display: none;
}
.customdropdown.active {
  display: block;
  border: 1px solid var(--content-color);
}
.customdropdown ul {
  padding: 0;
  margin: 0;
}
.custom-dropdown-wrap input {
  cursor: pointer;
  background: url(../Images/arrow_down.svg) no-repeat calc(100% - 10px) center;
}
[dir="rtl"] .custom-dropdown-wrap input {
  cursor: pointer;
  background: url(../Images/arrow_down.svg) no-repeat calc(10% - 10px) center;
}
.customdropdown ul li {
  list-style: none;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  color: black;
}
.customdropdown ul li.active-option,
.customdropdown ul li:hover {
  background-color: var(--focus-color);
  margin-bottom: 0;
  border-radius: 5px;
  color: white !important;
}
.pain-div {
  position: relative;
  padding-top: 25px;
  margin-top: 25px;
  text-align: center;
}
.answer-div img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
}

.pre-triage.body-tabs > .tab-content{
  height: 90%;
}
.pre-triage.body-tabs > .tab-content > [role="tabpanel"]{
  height: 100%;
}