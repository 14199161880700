body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.picker-container .picker-inner {
  font-size: 16px !important;
}
.cal-wrap { width: 100%; position: relative;}
.calender-wrapper {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 0;
  border-radius: 5px;
  background: var(--theme-color);
  text-align: center;
  z-index: 99;
  transform: translateX(-50%);
  margin-top: -5px;
}
.calender-wrapper2{
  top: 0;
  left: 0;
  width: 100%;
  transform: initial;
  margin-top: -54px;
  position: sticky;
}
.calender-wrapper img {
  width: 17px;
  padding: 2px 3px;
  border-radius: 100px;
  display: flex;
  margin: 5px 5px -5px auto;
  cursor: pointer;
  filter: invert(1);
}
.simple-btn {
  border: none;
  background: #004462;
  color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.calender-wrapper .picker-highlight {
  background: rgba(0,0,0,0.08);
}
.calender-wrapper .picker-highlight:before,
.calender-wrapper .picker-highlight:after { display: none !important;}

.input-container{
  display: flex;
  justify-content: space-between;
}
.dateWrapper{
  width: 32%;
  margin-top: 5px;
}
.dateWrapper input{
  width: 100%;
  padding:10px;
  background: #36b0ff;
  border: 1px solid white;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  outline: 0;
  position: relative;
}
.dateWrapper .form-select:focus {
  border-color: #ffffff99 !important;
  box-shadow: none;
}
.dateWrapper input:focus{
  border-color: #fff;
}
.dateWrapper .form-select{
  background-image: url(./Assets/Images/arrow_down.svg);
  background-repeat: no-repeat;
  background-position: right 5px center;
}
.dateWrapper input::placeholder{
  color: #fff;
}
.commitment_para{
  position: absolute;
    bottom: 85px;
    color: var(--content-color);
    text-decoration: underline;
    left: 50%;
    font-size: 13px;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.instructions_para{
    color: var(--content-color);
    text-decoration: underline;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}
.commitment_detail{
  height: -webkit-fill-available;
  overflow-y: scroll;
  margin-bottom: 0;
  color: var(--content-color);
    font-size: 13px;
    padding-right: 5px;
}
.commitment_detail ul{
  padding-left: 15px;
    margin-top: 15px;
}